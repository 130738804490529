<template>
  <div
      class="nav-filter table-gray"
      transition="fade"
      transition-mode="out-in"
  >
    <div class="table-responsive">
      <table
          :class="customClass"
          class="table mb-0"
      >
        <thead>
        <tr>
          <th
              :key="item.key"
              @click="item.sort ? sortChange(item.key) : false"
              class="table--th-view"
              v-for="item in thead"
          >
              <span class="d-flex align-items-center">
                {{ $t(item.label) }}
                <div
                    class="d-flex flex-column table-sort-icons"
                    v-if="item.sort"
                >
                  <span v-if="item.key == params.column">
                    <font-awesome-icon
                        :class="params.direction == 'asc' ? 'active' : ''"
                        class="caret-sort caret-sort-up"
                        icon="caret-up"
                    />
                    <font-awesome-icon
                        :class="params.direction == 'desc' ? 'active' : ''"
                        class="caret-sort caret-sort-down"
                        icon="caret-down"
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon
                        class="caret-sort caret-sort-up"
                        icon="caret-up"
                    />
                    <font-awesome-icon
                        class="caret-sort caret-sort-down"
                        icon="caret-down"
                    />
                  </span>
                </div>
              </span>
          </th>
        </tr>
        </thead>
        <draggable
            @end="endMove"
            element="tbody"
            v-model="model"
        >
          <tr
              :key="key"
              v-for="(row, key) in model? model : paginatedData"
          >
            <td
                :key="key + '_' + item.key"
                class="table--tbody"
                v-for="item in thead"
            >
              <router-link
                  :to="row_link  + '/' + row.id"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 100%;"
                  v-if="row_link"
              >
                <div
                    :class="item.deleteWrap ? 'deleteWrap' : ''"
                    style="cursor: pointer"
                >

                  <template>
                    <!--boolean type BEGIN-->
                    <template v-if="item.type === 'boolean'">
                    <span class="d-flex justify-content-center align-items-center">
                      <el-tooltip
                          :content="$t('system.change_active')"
                          effect="dark"
                          placement="bottom"
                          v-if="item.key === 'active'"
                      >
                      <el-checkbox
                          class="m-0"
                          v-model="row[item.key]"
                      ></el-checkbox>
                    </el-tooltip>
                    <el-tooltip
                        :content="$t('system.change_default')"
                        effect="dark"
                        placement="bottom"
                        v-else-if="item.key === 'is_default'"
                    >
                      <el-checkbox
                          @click.native="changeDefault(row)"
                          class="m-0"
                          v-model="row[item.key]"
                      >
                      </el-checkbox>
                      <!--<div @click="changeDefault(scope.row)">-->
                      <!--<v-icon v-if="scope.row[item.key]" name="check"></v-icon>-->
                      <!--<v-icon v-else name="times"></v-icon>-->
                      <!--</div>-->
                    </el-tooltip>
                    <el-tooltip
                        :content="$t('system.change_issue_close')"
                        effect="dark"
                        placement="bottom"
                        v-else-if="item.key === 'issue_close'"
                    >
                      <el-checkbox
                          class="m-0"
                          v-model="row[item.key]"
                      ></el-checkbox>
                      <!--<div @click="scope.row[item.key] =! scope.row[item.key]">-->
                      <!--<v-icon v-if="scope.row[item.key]" name="check"></v-icon>-->
                      <!--<v-icon v-else name="times"></v-icon>-->
                      <!--</div>-->
                    </el-tooltip>
                    </span>
                    </template>
                    <!--boolean type END-->

                    <!--action type BEGIN-->
                    <template v-else-if="item.type === 'action'">
                    <span v-if="row.edit">
                      <el-button
                          :loading="loading.confirm_edit"
                          @click="confirmEdit(row); row.edit =! row.edit"
                          size="mini"
                          type="success"
                      >
                        {{ $t('system.save') }}
                      </el-button>
                      <el-button
                          :loading="loading.cancel_edit"
                          @click="cancelEdit(row)"
                          class="cancel-btn"
                          size="mini"
                      >
                        {{ $t('system.cancel') }}
                      </el-button>
                    </span>
                      <span v-else>
                      <el-button
                          @click="row.originalName = row.name; row.edit=!row.edit"
                          name="edit"
                          size="mini"
                          type="info"
                      >
                        {{ $t('system.edit') }}
                      </el-button>
                      <el-button
                          @click.stop="removeElement(row)"
                          name="trash"
                          size="mini"
                          type="danger"
                      >
                        {{ $t('system.delete') }}
                      </el-button>
                    </span>
                    </template>
                    <!--action type END-->
                    <!--select type BEGIN-->
                    <template v-else-if="item.type === 'select' && !item.edit">
                      <ab-select
                          :item="item"
                          :options="selectOptions"
                          :scope="row"
                          :selected="selected"
                      ></ab-select>
                    </template>
                    <!--select type END-->

                    <template v-else>
                    <span v-if="item.edit && row.edit">
                      <span
                          v-if="item.type === 'hours'"
                          v-permission="item.permission ? item.permission : []"
                      >
                        <el-input
                            name="hours"
                            type="text"
                            v-mask="item.mask"
                            v-model="row[item.key]"
                        >
                        </el-input>
                      </span>
                      <span
                          v-else-if="item.type === 'date'"
                          v-permission="item.permission ? item.permission : []"
                      >
                        <el-date-picker
                            :pickerOptions="{ firstDayOfWeek: 1 }"
                            format="yyyy-MM-dd"
                            placeholder=""
                            type="date"
                            v-model="scope.row[item.key]"
                        >
                        </el-date-picker>
                      </span>
                      <span
                          v-else-if="item.type === 'datetime'"
                          v-permission="item.permission ? item.permission : []"
                      >
                        <el-date-picker
                            :clearable="item.clearable"
                            :pickerOptions="{ firstDayOfWeek: 1 }"
                            placeholder=""
                            type="datetime"
                            v-model="row[item.key]"
                        >
                        </el-date-picker>
                      </span>
                      <span
                          v-else-if="item.type === 'list' && item.key === 'activity_id'"
                          v-permission="item.permission ? item.permission : []"
                      >
                        <el-select
                            filterable
                            v-model="row[item.key]"
                        >
                          <el-option
                              :label="item.name"
                              :value="item.id"
                              v-bind:key="row[item.key]"
                              v-for="item in time_entry_activity"
                          >
                          </el-option>
                        </el-select>
                      </span>
                      <span
                          v-else-if="item.type === 'list' && item.key === 'user_id'"
                          v-permission="item.permission ? item.permission : []"
                      >
                        <el-select
                            filterable
                            v-model="row[item.key]"
                        >
                          <el-option
                              :label="item.name"
                              :value="item.id"
                              v-bind:key="row[item.key]"
                              v-for="item in users"
                          >
                          </el-option>
                        </el-select>
                      </span>
                      <div
                          v-else
                          v-permission="item.permission ? item.permission : []"
                      >
                        <el-input
                            clearable
                            v-model="row[item.key]"
                        />
                      </div>
                    </span>

                      <span v-else>
                      <router-link
                          :to="item.url+'/'+row.id"
                          class="abTable--link"
                          v-if="item.link"
                      >
                        {{ row[item.key] }}
                      </router-link>
                      <span v-else-if="item.object">
                        {{
                          (row[item.object.key]) ? (row[item.object.key][item.object.value] ?
                                                    row[item.object.key][item.object.value] : '') : ''
                        }}
                      </span>
                      <span v-else>
                        {{ row[item.key] ? row[item.key] : '' }}
                      </span>
                    </span>
                    </template>
                  </template>
                </div>
              </router-link>
              <div
                  :class="item.deleteWrap ? 'deleteWrap' : ''"
                  v-else
              >
                <template>
                  <!--boolean type BEGIN-->
                  <template v-if="item.type === 'boolean'">
                  <span class="d-flex justify-content-center align-items-center">
                      <el-tooltip
                          :content="$t('system.change_active')"
                          effect="dark"
                          placement="bottom"
                          v-if="item.key === 'active'"
                      >
                      <el-checkbox
                          class="m-0"
                          v-model="row[item.key]"
                      ></el-checkbox>
                    </el-tooltip>
                    <el-tooltip
                        :content="$t('system.change_default')"
                        effect="dark"
                        placement="bottom"
                        v-else-if="item.key === 'is_default'"
                    >
                      <el-checkbox
                          @click.native="changeDefault(row)"
                          class="m-0"
                          v-model="row[item.key]"
                      >
                      </el-checkbox>
                      <!--<div @click="changeDefault(scope.row)">-->
                      <!--<v-icon v-if="scope.row[item.key]" name="check"></v-icon>-->
                      <!--<v-icon v-else name="times"></v-icon>-->
                      <!--</div>-->
                    </el-tooltip>
                    <el-tooltip
                        :content="$t('system.change_issue_close')"
                        effect="dark"
                        placement="bottom"
                        v-else-if="item.key === 'issue_close'"
                    >
                      <el-checkbox
                          class="m-0"
                          v-model="row[item.key]"
                      ></el-checkbox>
                      <!--<div @click="scope.row[item.key] =! scope.row[item.key]">-->
                      <!--<v-icon v-if="scope.row[item.key]" name="check"></v-icon>-->
                      <!--<v-icon v-else name="times"></v-icon>-->
                      <!--</div>-->
                    </el-tooltip>
                    </span>
                  </template>
                  <!--boolean type END-->
                  <!--colorPicker type BEGIN-->
                  <template v-else-if="item.type === 'colorPicker'">
                  <span class="abTable--colorPicker">
                    <el-color-picker
                        size="small"
                        v-model="row.color"
                    ></el-color-picker>
                  </span>
                  </template>
                  <!--colorPicker type END-->
                  <!--action type BEGIN-->
                  <template v-else-if="item.type === 'action'">
                  <span v-if="row.edit">
                    <el-button
                        :loading="loading.confirm_edit"
                        @click="confirmEdit(row); row.edit =! row.edit"
                        size="mini"
                        type="success"
                    >
                      {{ $t('system.save') }}
                    </el-button>
                    <el-button
                        :loading="loading.cancel_edit"
                        @click="cancelEdit(row)"
                        class="cancel-btn"
                        size="mini"
                    >
                      {{ $t('system.cancel') }}
                    </el-button>
                  </span>
                    <span v-else>
                    <el-button
                        @click="row.originalName = row.name; row.edit=!row.edit"
                        name="edit"
                        size="mini"
                        type="info"
                    >
                      {{ $t('system.edit') }}
                    </el-button>
                    <el-button
                        @click.stop="removeElement(row)"
                        name="trash"
                        size="mini"
                        type="danger"
                    >
                      {{ $t('system.delete') }}
                    </el-button>
                  </span>
                  </template>
                  <!--action type END-->
                  <!--select type BEGIN-->
                  <template v-else-if="item.type === 'select' && !item.edit">
                    <ab-select
                        :item="item"
                        :options="selectOptions"
                        :scope="row"
                        :selected="selected"
                    ></ab-select>
                  </template>
                  <!--select type END-->

                  <template v-else>
                  <span v-if="item.edit && row.edit">
                    <span
                        v-if="item.type === 'hours'"
                        v-permission="item.permission ? item.permission : []"
                    >
                      <el-input
                          name="hours"
                          type="text"
                          v-mask="item.mask"
                          v-model="row[item.key]"
                      >
                      </el-input>
                    </span>
                    <span
                        v-else-if="item.type === 'date'"
                        v-permission="item.permission ? item.permission : []"
                    >
                      <el-date-picker
                          :pickerOptions="{ firstDayOfWeek: 1 }"
                          format="yyyy-MM-dd"
                          placeholder=""
                          type="date"
                          v-model="scope.row[item.key]"
                      >
                      </el-date-picker>
                    </span>
                    <span
                        v-else-if="item.type === 'datetime'"
                        v-permission="item.permission ? item.permission : []"
                    >
                      <el-date-picker
                          :clearable="item.clearable"
                          :pickerOptions="{ firstDayOfWeek: 1 }"
                          placeholder=""
                          type="datetime"
                          v-model="row[item.key]"
                      >
                      </el-date-picker>
                    </span>
                    <span
                        v-else-if="item.type === 'list' && item.key === 'activity_id'"
                        v-permission="item.permission ? item.permission : []"
                    >
                      <el-select
                          filterable
                          v-model="row[item.key]"
                      >
                        <el-option
                            :label="item.name"
                            :value="item.id"
                            v-bind:key="row[item.key]"
                            v-for="item in time_entry_activity"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span
                        v-else-if="item.type === 'list' && item.key === 'user_id'"
                        v-permission="item.permission ? item.permission : []"
                    >
                      <el-select
                          filterable
                          v-model="row[item.key]"
                      >
                        <el-option
                            :label="item.name"
                            :value="item.id"
                            v-bind:key="row[item.key]"
                            v-for="item in users"
                        >
                        </el-option>
                      </el-select>
                    </span>
                    <span
                        v-else
                        v-permission="item.permission ? item.permission : []"
                    >
                      <el-input
                          clearable
                          v-model="row[item.key]"
                      />
                    </span>
                  </span>

                    <span v-else>
                    <router-link
                        :to="item.url+'/'+ row.id"
                        class="abTable--link"
                        v-if="item.link && row.id"
                    >
                      {{ row[item.key] }}
                    </router-link>
                    <router-link
                        :to="item.url+'/'+ row.clientId"
                        class="abTable--link"
                        v-else-if="item.link && row.clientId"
                    >
                      {{ row[item.key] }}
                    </router-link>
                    <span v-else-if="item.object">
                      {{
                        (row[item.object.key]) ? (row[item.object.key][item.object.value] ?
                                                  row[item.object.key][item.object.value] : '') : ''
                      }}
                    </span>
                    <span v-else>
                      {{ row[item.key] ? row[item.key] : '' }}
                    </span>
                  </span>
                  </template>
                </template>
              </div>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
    <!--PAGINATE-->
    <el-card
        class="pagination-container mt-3"
        shadow="never"
        v-if="paginate && paginateShow"
    >
      <el-pagination
          :current-page="params.page"
          :page-size="params.per_page"
          :page-sizes="[10, 30, 50, 100, 300]"
          :total="totalRows"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';
import swal from 'sweetalert2';
import moment from 'moment';
import draggable from 'vuedraggable';
// import Sortable from 'sortablejs'
import ABselect from './select.vue';
import notification from '../../notification/notify';

export default {
  props: ['data', 'source', 'thead', 'options', 'customClass', 'project_id'],
  components: {
    'ab-select': ABselect,
    draggable,
  },
  computed: {
    defaultSort: function () {
      return {prop: this.default_sort};
    },
    selectOptions: function () {
      return {'forms': this.$parent.forms};
    },
    totalRows() {
      if (this.total) {
        return this.total;
      } else if (this.data) {
        return this.data.length;
      } else {
        return 0;
      }
    },
    paginatedData() {
      if (this.data) {
        return this.data.slice(this.params.per_page * (this.params.page - 1), this.params.per_page * this.params.page);
      }
      return [];
    },
  },
  data() {
    return {
      selected: [],
      model: null,
      params: {
        column: 'position',
        direction: 'asc',
        per_page: 10,
        page: 1,
      },
      loading: {
        table: true,
        confirm_edit: false,
        cancel_edit: false,
      },
      total: 0,
      newList: [],
      row_link: false,
      custom_params: '',
      paginate: true,
      paginateShow: true,
      sortable: false,
      default_sort: 'id',
      inline_edit: false,
      row_click: false,
      time_entry_activity: [],
      users: [],
    };
  },
  beforeMount() {
    let vm = this;
    if (typeof vm.options !== 'undefined') {
      if (typeof vm.options.params !== 'undefined') {
        _.forEach(vm.options.params, function (value, key) {
          vm.custom_params += `&${key}=${value}`;
        });
      }
      if (typeof vm.options.paginateShow !== 'undefined' || vm.options.paginateShow) {
        vm.paginateShow = vm.options.paginateShow;
      }
      // if (typeof vm.options.paginate === 'undefined' || !vm.options.paginate) {
      //   vm.paginate = false
      // }
      // if (typeof (vm.options.sortable) !== 'undefined') {
      //   vm.sortable = vm.options.sortable
      // }
      if (typeof (vm.options.default_sort) !== 'undefined') {
        vm.default_sort = vm.options.default_sort;
      }
      if (typeof (vm.options.inline_edit) !== 'undefined') {
        vm.inline_edit = vm.options.inline_edit;
      }
      if (typeof (vm.options.row_click) !== 'undefined') {
        vm.row_click = vm.options.row_click;
      }
      if (typeof (vm.options.row_link) !== 'undefined') {
        vm.row_link = vm.options.row_link;
      }
      if (typeof (vm.options.time_entry_activity) !== 'undefined') {
        this.getTimeEntryActivity();
      }
      if (typeof (vm.options.users) !== 'undefined') {
        this.getUsers();
      }
    }
    if (typeof (vm.options.fetchData) === 'undefined') {
      this.fetchData();
    }
  },
  mounted: function () {
    // if (this.sortable) {
    //   this.$nextTick(() => {
    //     this.setSort()
    //   })
    // }
  },
  methods: {
    endMove() {
      this.newList = this.model;
    },
    rowClick(row) {
      if (this.row_click) {
        this.$router.push(`${this.source}/${row.id}`);
      }
    },
    fetchData() {
      let vm = this;
      vm.loading.table = true;
      vm.url = this.buildURL();
      axios.get(vm.url, {
        params: {
          ...vm.filters,
        },
      })
          .then(function (response) {
            vm.model = response.data.data;
            vm.model.forEach(function (item) {
              item.is_default = !!item.is_default;
              item.active = !!item.active;
            });
            if (typeof response.data.total !== 'undefined') {
              vm.total = response.data.total;
            } else {
              if (vm.paginate) {
                vm.params.current_page = parseInt(response.data.meta.current_page);
                vm.params.from = parseInt(response.data.meta.from);
                vm.params.last_page = parseInt(response.data.meta.last_page);
                vm.params.per_page = parseInt(response.data.meta.per_page);
                vm.params.to = parseInt(response.data.meta.to);
                vm.total = parseInt(response.data.meta.total);
              }
            }
            vm.newList = vm.model.slice();
            vm.loading.table = false;
          })
          .catch(function () {
            vm.loading.table = false;
          });

      vm.loading.table = false;
    },
    buildURL() {
      const p = this.params;
      if (this.paginate) {
        return `${this.source}?column=${p.column}&direction=${p.direction}&per_page=${p.per_page}&page=${p.page}${this.custom_params}`;
      } else {
        return `${this.source}?column=${p.column}&direction=${p.direction}${this.custom_params}`;
      }
    },
    // setSort () {
    //   const el = document.querySelectorAll(`.${this.$props.customClass} .el-table__body-wrapper > table > tbody`)[0];
    //   this.sortable = Sortable.create(el, {
    //     ghostClass: 'sortable-ghost',
    //     setData: function (dataTransfer) {
    //       dataTransfer.setData('Text', '')
    //     },
    //     onEnd: evt => {
    //       const targetRow = this.model.splice(evt.oldIndex, 1)[0];
    //       this.model.splice(evt.newIndex, 0, targetRow);
    //
    //       const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
    //       this.newList.splice(evt.newIndex, 0, tempIndex)
    //     }
    //   })
    // },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchData();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchData();
      }
    },
    sortChange(column) {
      if (this.paginate) {
        if (typeof column !== 'undefined' && column !== null) {
          if (column === this.params.column) {
            if (this.params.direction === 'desc') {
              this.params.direction = 'asc';
            } else {
              this.params.direction = 'desc';
            }
          } else {
            this.params.column = column;
            this.params.direction = 'asc';
          }
          this.fetchData();
        }
      }
    },
    changeDefault(element) {
      this.model = _.map(this.model, function (item) {
        item.id === element.id ? item.is_default = element.is_default : item.is_default = false;
        return item;
      });
    },
    removeElement: function (element) {
      let vm = this;
      swal.fire({
        title: vm.$t('system.remove'),
        text: vm.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: vm.$t('system.yes'),
        cancelButtonText: vm.$t('system.no'),
      }).then(function (resp) {
        if (resp.value) {
          if (element.id) {
            axios.delete(`${vm.source}/${element.id}`)
                .then(function (response) {
                  if (vm.model) {
                    vm.model.splice(vm.model.indexOf(element), 1);
                  } else if (vm.newList) {
                    vm.newList.splice(vm.newList.indexOf(element), 1);
                  } else if (vm.data) {
                    vm.data.splice(vm.data.indexOf(element), 1);
                  }
                  vm.$emit('refresh');
                  notification.notify(
                      vm.$t('notify.success'),
                      vm.$t('system.deleted'),
                      'success');
                })
                .catch(function (error) {
                  notification.notify(
                      vm.$t('notify.error'),
                      error.response.data.error,
                      'error');
                });
          }
        }
      });
    },
    cancelEdit(row) {
      this.loading.cancel_edit = true;
      row.name = row.originalName;
      row.edit = false;
      // this.$message({
      //   message: this.$t('system.the_title_has_been_restored_to_the_original_value'),
      //   type: 'warning'
      // })
      this.loading.cancel_edit = false;
    },
    confirmEdit(row) {
      let vm = this;
      this.loading.confirm_edit = true;
      if (vm.inline_edit) {
        let formData = new FormData();
        for (let property in row) {
          if (row.hasOwnProperty(property)) {
            if (row['hours'] === '') {
              row['hours'] = '000:00';
            }
            if (row[property] instanceof Date) {
              row[property] = moment(row[property]).format('YYYY-MM-DD HH:mm:ss');
            }
            formData.append(property, JSON.stringify(row[property]));
          }
        }
        const config = {headers: {'Content-Type': `multipart/form-data`}};
        axios.post(`${vm.source}/${row.id}`, formData, config)
            .then(function (response) {
              vm.loading.confirm_edit = false;
              if (response.data.saved) {
                row.edit = false;
                row.originalName = row.name;
                if (response.data.model) {
                  row.user = response.data.model.user;
                  row.activity = response.data.model.activity;
                }
                notification.notify(
                    vm.$t('notify.success'),
                    response.data.message,
                    'success');
              } else {
                notification.notify(
                    vm.$t('notify.error'),
                    response.data.message,
                    'error');
              }
            })
            .catch(function (error) {
              vm.loading.confirm_edit = false;
              if (typeof error !== 'undefined') {
                notification.notify(
                    vm.$t('notify.error'),
                    error.response.data.errors.name[0],
                    'error');
              }
            });
      } else {
        row.edit = false;
        row.originalName = row.name;
        notification.notify(
            vm.$t('notify.success'),
            vm.$t('system.the_title_has_been_edited'),
            'success');
        this.loading.confirm_edit = false;
      }
    },
    getTimeEntryActivity() {
      let vm = this;
      axios.get('dictionaries?type=time_entry_activity').then(function (response) {
        vm.time_entry_activity = _.keyBy(response.data.options, 'id');
      }).catch(function (error) {
        notification.notify(
            vm.$t('notify.error'),
            error.response.data.error,
            'error');
      });
    },
    getUsers() {
      let vm = this;
      axios.get('get_user_by_project',
          {
            params: {
              'project_id': this.project_id,
            },
          },
      ).then(function (response) {
        if (response.data.users.length > 0) {
          _.forEach(response.data.users, function (value, key) {
            vm.users.push({
              id: value.id,
              name: value.name,
              value: value.name,
              label: value.name,
              disabled: false,
            });
          });
        } else {
          notification.notify(
              vm.$t('notify.warning'),
              vm.$t('message.issue_user_empty'),
              'warning');
        }
      }).catch(function (error) {
        notification.notify(
            vm.$t('notify.error'),
            error.response.data.error,
            'error');
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import './../../static/css/table';

th {
  span {
    justify-content: flex-start;
  }
}

span {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.abTable--link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table--tbody div {
  height: 100%;
}

.abTable--colorPicker {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    height: auto;
  }
}
</style>
