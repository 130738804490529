<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-input
            :placeholder="$t('system.add_new_group_dictionery')"
            @keyup.enter.native="addGroup"
            autocomplete="off"
            autofocus="autofocus"
            v-model="newGroupName"
        >
          <template slot="prepend">{{ $t('system.add_new_group_dictionery') }}</template>
        </el-input>
      </div>
    </div>
    <div
        :key="type"
        class="view"
        v-for="type in types"
    >
      <div class="view-body">
        <enum-item
            :forms="forms"
            :ref="type"
            :type="type"
            @addItem="addItem"
        ></enum-item>
      </div>
    </div>

    <!--<div class="box-button align-right" >-->
    <el-button
        @click="updateData"
        class="fly-button"
        type="primary"
    >{{ $t('system.save') }}
    </el-button>
    <!--</div>-->

  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import EnumItem from './EnumItem.vue';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'EnumerationIndex',
  components: {
    EnumItem,
  },
  data: function () {
    return {
      types: [],
      newGroupName: '',
      resource: 'enumeration',
      redirect: '/enumeration',
      refresh: false,
      forms: [],
    };
  },
  beforeMount() {
    let vm = this;
    this.getAllEnum();
    axios.get('dictionaries?type=forms').then(function (response) {
      _.forEach(response.data.forms, function (value, key) {
        vm.forms.push({
          id: parseInt(value.id),
          name: value.filename,
          value: value.filename,
          label: value.filename,
          enumeration_id: value.enumeration_id,
          disabled: !value.active,
          type: value.type,
        });
      });
    }).catch(function (error) {
      notification.notify(
          vm.$t('notify.error'),
          vm.$t(error.response.data.error),
          'error');
    });
  },
  methods: {
    getAllEnum() {
      return new Promise(resolve => {
        let vm = this;
        axios.get(`${this.resource}`).then(function (response) {
          Vue.set(vm.$data, 'types', response.data.data);
        });
        resolve();
      });
    },
    addGroup() {
      let vm = this;
      let value = vm.newGroupName;
      vm.newGroupName = '';
      if (!value) {
        return;
      }
      if (vm.types.includes(value)) {
        notification.notify(
            vm.$t('notify.error'),
            vm.$t('system.there_is_a_group_with_this_name') + ' ' + value,
            'error');
        return;
      }
      if (vm.types.length > 0) {
        vm.types.unshift(value);
      }
    },
    addItem(e, type) {
      let vm = this;
      if (typeof this.$refs[type] === 'undefined') {
        return;
      }
      if (typeof this.$refs[type][0] === 'undefined') {
        return;
      }
      let model = this.$refs[type][0].$refs.ABtable.newList;
      if (model === null) {
        this.$refs[type][0].$refs.ABtable.newList = [];
      }
      let count = Object.keys(model).length;
      let value = e.target.value && e.target.value.trim();
      if (!value) {
        return;
      }
      let newObject = {
        fixed: false,
        name: value,
        position: count + 1,
        active: false,
        edit: false,
        type: type,
        deleted_at: null,
      };

      axios.post(`add_item_enum`, {newObject})
          .then(function (response) {
            if (response.data.saved) {
              vm.$refs[type][0].$refs.ABtable.model.push(response.data.model);
              vm.$refs[type][0].$refs.ABtable.newList.push(response.data.model);
              notification.notify(
                  vm.$t('notify.success'),
                  vm.$t('system.added') + ' ' + vm.$t(`system.${type}`),
                  'success');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    updateData: function () {
      let vm = this;
      let data = [];
      _.forEach(vm.$refs, function (value) {
        data.push(value[0].$refs.ABtable.newList);
      });
      axios.post(`${vm.resource}`, {data})
          .then(function (response) {
            if (response.data.saved) {
              vm.getAllEnum();
              notification.notify(
                  vm.$t('notify.success'),
                  vm.$t(response.data.message),
                  'success');
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  vm.$t(response.data.message),
                  'error');
            }
          })
          .catch(function (error) {
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 1);
            notification.notify(
                vm.$t('notify.error'),
                errors,
                'error');
            Vue.set(vm.$data, 'backend_errors', dataErrors);
          });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.fly-button {
  position: fixed;
  bottom: 80px;
  right: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}
</style>
<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: .5;
  background: #C8EBFB;
}

.row-item {
  cursor: move;
}

.row-item i {
  cursor: pointer;
}
</style>
