<template>
  <div>
    <div
        class="clearfix mb-3"
        slot="header"
    >
      <el-input
          :placeholder="$t('system.add_element')"
          @keyup.enter.native="addItem($event, type)"
          v-model="value"
      >
        <template slot="prepend"><b>{{ $t(`system.${type}`) }}</b></template>
      </el-input>
    </div>
    <div class="nav-filter table-gray">
      <ab-table
          :custom-class="customClass"
          :options="options"
          :source="resource"
          :thead="thead"
          ref="ABtable"
      ></ab-table>
    </div>
  </div>
</template>

<script>
import ABtable from '../../components/ABtable/index.vue';

export default {
  name: 'EnumItem',
  props: {
    forms: {
      type: Array,
    },
    type: String,
  },
  components: {
    'ab-table': ABtable,
  },
  data() {
    return {
      value: '',
      editable: true,
      resource: 'enumeration',
      redirect: '/enumeration',
      editedElement: null,
      newElement: '',
      options: {
        params: {
          type: this.type,
        },
        default_sort: 'position',
        sortable: true,
        inline_edit: true,
      },
      customClass: this.type + '-table',
      thead: [
        {
          label: '',
          key: 'id',
          sort: false,
          width: '50',
        },
        {
          label: 'administration.name',
          key: 'name',
          sort: true,
          edit: true,
        },
        {
          label: 'administration.old_position',
          key: 'position',
          sort: true,
        },
        {
          label: 'administration.active',
          key: 'active',
          sort: false,
          type: 'boolean',
        },
        {
          label: 'administration.default',
          key: 'is_default',
          sort: false,
          type: 'boolean',
        },
      ],
    };
  },
  beforeMount() {
    if (this.type === 'tracker') {
      this.thead.push({
        label: '',
        key: 'form',
        sort: false,
        type: 'select',
      });
    }
    if (this.type === 'issue_status') {
      this.thead.push(
          {
            label: 'administration.issue_close',
            key: 'issue_close',
            sort: false,
            type: 'boolean',
          });
      this.thead.push(
          {
            label: 'administration.color',
            key: 'color',
            sort: false,
            type: 'colorPicker',
          });
    }

    this.thead.push(
        {
          label: '',
          key: 'action',
          sort: false,
          type: 'action',
        });
  },
  methods: {
    addItem(event, item) {
      this.$emit('addItem', event, item);
    },
  },
};
</script>
